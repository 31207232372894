const actions = {
  
 async autocomplete({ commit }, params) {
    const response = await this.$repositories.map.autocomplete(params)
    
    return response.data
  },
 async placeDetails({ commit }, params) {
    const response = await this.$repositories.map.placeDetails(params)
    
    return response.data
  },

}
export default actions
