const controller = '/property'
export default axios => ({
  getPropertyList(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.get(`${controller}/properties?Page=1&ItemsPerPage=20`, obj)
  },
  getPropertyDetails(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.get(`${controller}/properties/${obj.id}`, obj)
  },
  sendPropertyContact(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.post(`${controller}/get-properties/${obj.id}/contact`, obj)
  },
  search(obj) {
    axios.defaults.headers.common['Authorization'] = null
    let parmas = `?itemsPerPage=${obj.pageInfo.itemsPerPage}&page=${obj.pageInfo.currentPage}`
    return axios.post(`${controller}/search${parmas}`, obj.obj)
  },
  searchMap(obj) {
    axios.defaults.headers.common['Authorization'] = null
    let parmas = `?itemsPerPage=${obj.pageInfo.itemsPerPage}&page=${obj.pageInfo.currentPage}`
    return axios.post(`${controller}/search-clusters${parmas}`, obj.obj)
  },
  getPast10daysListing(obj) {
    axios.defaults.headers.common['Authorization'] = null
    let parmas = `?itemsPerPage=${obj.pageInfo.itemsPerPage}&page=${obj.pageInfo.currentPage}`
    return axios.get(`${controller}/past10days${parmas}`, obj.obj)
  },
})
