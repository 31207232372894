
const controller = '/maps/api/place'
export default axios => ({
  autocomplete(obj) {
    axios.defaults.headers.common['Authorization'] = null
    console.log("obj",obj)
    return axios.get(`${controller}/autocomplete/json?input=${obj.text}`)
  },
  placeDetails(obj) {
    axios.defaults.headers.common['Authorization'] = null

    return axios.get(`${controller}/details/json?place_id=${obj.placeId}`)
  },
  
})
